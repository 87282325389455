const buttonClickedReducer = (isOpen = false, action) => {
    switch (action.type) {
        case 'BUTTON_CLICKED_ON':
            console.log('BUTTON_CLICKED_ON');
            isOpen = true
            return isOpen;
        case 'BUTTON_CLICKED_OFF':
            console.log('BUTTON_CLICKED_OFF');
            isOpen = false
            return isOpen;
        default:
            return isOpen;
    }

}
export default buttonClickedReducer;