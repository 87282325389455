import { combineReducers } from "redux";

import card1Reducer from "./Redux/reducers/card1";
import card2Reducer from "./Redux/reducers/card2";
import card3Reducer from "./Redux/reducers/card3";
import card4Reducer from "./Redux/reducers/card4";
import card5Reducer from "./Redux/reducers/card5";
import buttonClickedReducer from "./Redux/reducers/buttonClickedReducer";

const rootReducer = combineReducers({
  card1Reducer: card1Reducer,
  card2Reducer: card2Reducer,
  card3Reducer: card3Reducer,
  card4Reducer: card4Reducer,
  card5Reducer: card5Reducer,
  buttonClickedState: buttonClickedReducer,
});

export default rootReducer;
