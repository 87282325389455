import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import "./App.css";
import "./index.css"

import Loadable from "./Components/Loadable";
const LandingPageCC = Loadable(lazy(() => import('./Components/CocaCola/0_LandingPage/LandingPage')));
const VideoPageCC = Loadable(lazy(() => import('./Components/CocaCola/5_VideoPlayer/VideoPlayer')));
const LandingPageCasino = Loadable(lazy(() => import('./Components/CasinoOmni/0_LandingPage/LandingPage')));
const VideoPageCasino = Loadable(lazy(() => import('./Components/CasinoOmni/5_VideoPlayer/VideoPlayer')));

function RedirectToExternal() {
  React.useEffect(() => {
    window.location.href = "http://3rockglobal.com/";
  }, []);
  return null; // Optionally, you can render a loading indicator or a message.
}
function App() {


  return (
    
    <Suspense fallback={<div className="loading">Loading...</div>}>
      <Routes>
        <Route path="/" element={<RedirectToExternal />} />
        <Route path="/ccdooh" element={<VideoPageCC />} />
        <Route path="/ccdoohmobile" element={<LandingPageCC />} />
        <Route path="/casinodooh" element={<VideoPageCasino />} />
        <Route path="/casinodoohmobile" element={<LandingPageCasino />} />
        <Route path="*" element={<RedirectToExternal />} />
      </Routes>
    </Suspense>
  );
}

export default App;
